import React from "react"
import {StaticImage} from "gatsby-plugin-image"
import App from '../../components/app'
import BannerLayout from '../../components/banner-layout'
// import BannerLayout from '../../components/banner-layout'
import Encomendar from '../../components/encomendar'

import ogImage from "../../assets/images/feijoada-brasileira-01.jpg"

const title = "Feijoada Brasileira da Lini"

const description = "A Feijoada da Lini: É uma receita tradicional da nossa família há muitos anos, uma Feijoada Brasileira com longa tradição. Fiz umas pequenas alteracões e adaptei a feijoada ao gosto português. O resultado é uma Feijoada Brasileira muito gostosa para ocasiões especiais. Bom apetite!"

const BannerImage = <StaticImage src="../../assets/images/feijoada-brasileira-01.jpg" alt={title} className="uk-height-medium uk-animation-fade"/>

export default function FeijoadaBrasileira({location}) {
  return <App location={location} title={title} description={description} image={ogImage}>
    <BannerLayout title={title} bannerImage={BannerImage}>
      <section className="uk-section uk-section-small">
        <div className="uk-container uk-text-center">
          <p className="uk-text-lead">{description}</p>
          <Encomendar/>
        </div>
      </section>

      <section className="uk-section uk-section-small">
        <StaticImage className="uk-animation-fade" src="../../assets/images/feijoada-brasileira-02.jpg" alt="Feijoada Brasilieira da Lini - receita original" objectFit="contain"/>
      </section>

      <section className="uk-section uk-section-small">
        <div className="uk-container">
          <table className="uk-table uk-table-middle uk-table-divider uk-table-hover uk-background-default">
            <thead>
              <tr>
                <th>Tamanho</th>
                <th className="uk-text-right">Preço</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Feijoada Brasileira completa (ca. 1.800 g). Com farofa de mandioca, couve, arroz e laranja. Serve para quatro a seis pessoas:</td>
                <td className="uk-text-right uk-text-nowrap">29,90 €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className="uk-section uk-section-small">
        <div className="uk-container uk-text-center">
          <Encomendar/>
        </div>
      </section>

      <section className="uk-section uk-section-small uk-text-small">
        <div className="uk-container">
          <p>Ingredientes: Feijão preto, carne bovina variada, bacon, chouriço, cebola, alho, couve, farofa de mandioca, sal, pimenta.</p>
        </div>
      </section>
    </BannerLayout>
  </App>
}
